import React, { useEffect, useState } from "react";
import { useUser } from "../components/UserContext";

export default function Advice(): JSX.Element {
	const { advice } = useUser();
	const [showFullAdvice, setShowFullAdvice] = useState(false);

	// store advice to session storage
	useEffect(() => {
		if (advice) localStorage.setItem("advice", advice);
	}, [advice]);

	const shortAdvice = advice.slice(0, 500);

	function unlockFullAdvice() {
		setShowFullAdvice(true);
	}

	if (!showFullAdvice)
		return (
			<main>
				<div className="forms">
					<div className="infoCard shortAdvice">
						<h3>Advice</h3>
						<p>{formatAdvice(shortAdvice)}...</p>
					</div>
					<div className="infoCard ">
						<strong>Do you want to read the full advice?</strong>
						<p>A one time payment of ... euro's will unlock the full advice</p>
						<button onClick={unlockFullAdvice}>Get full advice</button>
					</div>
				</div>
			</main>
		);
	return (
		<main>
			<div className="forms">
				<div className="infoCard">
					<h3>Advice</h3>
					<p>{formatAdvice(advice)}</p>
					{/* <p>{advice}</p> */}
				</div>
			</div>
		</main>
	);
}

function formatAdvice(advice: string) {
	// Replace colons with colon followed by a newline
	let formattedAdvice = advice.replace(/:/g, ":\n");

	// Ignore replacing '-' when between numbers (e.g., 2500-3300 or 2500 - 3300)
	formattedAdvice = formattedAdvice.replace(/(?<!\d\s*)-(?!\s*\d)/g, " ");

	// Use a regex to identify numbers immediately followed by letters and wrap them in a placeholder tag
	formattedAdvice = formattedAdvice.replace(/(\d+)(?=[A-Z])/gi, "<num>$1</num>");

	// Split the string into segments for JSX conversion
	const segments: JSX.Element[] = [];
	formattedAdvice.split("\n").forEach((segment, index) => {
		const trimmedSegment = segment.trim();
		if (!trimmedSegment) return;

		// Trim spaces and check if the segment ends with a colon
		const isBold = trimmedSegment.endsWith(":");

		// Add either a bold or regular text element to the segments array
		segments.push(
			isBold ? (
				<>
					<br />
					<strong key={`strong-${index}`}>{segment}</strong>{" "}
				</>
			) : (
				<span key={`span-${index}`}>{segment}</span>
			)
		);

		// Add a break after each line
		segments.push(<br key={`br-${index}`} />);
	});

	return <>{segments}</>;
}
