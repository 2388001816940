import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../UserContext";

export default function GeneralInformation({ formIndex }: { formIndex: number }): JSX.Element {
	const { formProgressParam } = useParams();
	const { clientInfo, handleChangeClientInfo } = useUser();
	const [locationCharCount, setLocationCharCount] = useState(0);
	// const [currencyCharCount, setCurrencyCharCount] = useState(0);

	const handleLocationChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setLocationCharCount(event.target.value.length);
		handleChangeClientInfo("location")(event);
	};

	const handleCurrencyChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		// setCurrencyCharCount(event.target.value.length);
		handleChangeClientInfo("currency")(event);
	};

	if (formIndex !== Number(formProgressParam)) return <></>;
	return (
		<>
			<div className="infoCard">
				<h3>General information</h3>
				<p>Where do you live (city/state/country)?</p>
				<textarea value={clientInfo?.location} onChange={handleLocationChange} maxLength={100} />
				<p>{locationCharCount}/100</p>
				<br />
				<p>In what currency should we specify the salary ranges?</p>
				<textarea
					style={{
						height: "3.2em", // Increased height to accommodate padding
						overflow: "hidden",
						padding: "1em", // Add padding as desired
						resize: "none", // Prevents resizing
						display: "flex",
						alignItems: "center", // This will not affect textareas, shown for illustrative purposes
					}}
					rows={1}
					value={clientInfo?.currency}
					onChange={handleCurrencyChange}
					maxLength={50}
				/>
				{/* <p>{currencyCharCount}/50</p> */}
			</div>
		</>
	);
}
