import * as e from "../data/enums";
import * as c from "../data/constants/constants";
import * as t from "../data/types/types";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FormProgress from "../components/FormProgress";
import CareerStage from "../components/Forms/CareerStage";
import { useAlert, useUser } from "../components/UserContext";
import WorkExperience from "../components/Forms/WorkExperience";
import EducationalBackground from "../components/Forms/EducationalBackground";
import Skills from "../components/Forms/Skills";
import AreasForImprovement from "../components/Forms/AreaForImprovement";
import Preferences from "../components/Forms/Preferences";
import Concerns from "../components/Forms/Concerns";
import Financial from "../components/Forms/Financial";
import GeneralInformation from "../components/Forms/GeneralInformation";
import Email from "../components/Forms/Email";
import Loading from "../components/Loading";
import chatGPTAPI from "../services/api/chatGPTAPI";
import ReasonForSwitch from "../components/Forms/ReasonForSwitch";
// import { capitalizeFirstLetter, preventDefaultContext, spaceBeforeCapitals } from "../../utils/utils";

export default function Form() {
	const { formIndex, setFormIndex } = useUser();
	const navigate = useNavigate();
	const { formProgressParam } = useParams(); // Grab the parameter from the URL

	useEffect(() => {
		const initialIndex = formProgressParam
			? parseInt(formProgressParam, 10)
			: parseInt(localStorage.getItem("formProgress") || "1", 10);

		if (!isNaN(initialIndex) && initialIndex !== formIndex) {
			setFormIndex(initialIndex); // Update your formIndex state based on URL or localStorage
		}
	}, [formIndex, formProgressParam, setFormIndex]);

	function goToPreviousSection() {
		const navigateTo = formIndex - 1;
		if (navigateTo < 0) return;
		localStorage.setItem("formProgress", navigateTo.toString());
		setFormIndex(navigateTo);
		navigate("/form/" + navigateTo);
	}

	function goToNextSection() {
		console.log("formIndex: ", formIndex, "c.totalFormPages: ", c.totalFormPages);
		const navigateTo = formIndex + 1;
		if (navigateTo > c.totalFormPages) return;
		localStorage.setItem("formProgress", navigateTo.toString());
		setFormIndex(navigateTo);
		navigate("/form/" + navigateTo);
	}

	const { clientInfo, setAdvice } = useUser(); // handleChangeClientInfo
	const { triggerAlert } = useAlert();
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	// const [firstNameCharCount, setFirstNameCharCount] = useState(0);
	// const [lastNameCharCount, setLastNameCharCount] = useState(0);
	// const [emailCharCount, setEmailCharCount] = useState(0);

	async function handleSubmit() {
		if (!clientInfo) return setErrorMessage("Sorry, we couldn't generate advice for you. Please try again.");
		const email = clientInfo.email;
		console.log(email);
		if (!email || email.length === 0) return setErrorMessage("Please enter your email.");
		if (!email.includes("@") || !email.includes(".")) return setErrorMessage("Please enter a valid email.");
		setErrorMessage("");

		clientInfo.email = email.toLocaleLowerCase().trim();

		try {
			setLoading(true);
			const advice = await chatGPTAPI(clientInfo);
			setAdvice(advice || "Sorry, we couldn't generate advice for you. Please try again.");
			navigate("/advice");
		} catch (error) {
			triggerAlert("Sorry, we couldn't generate advice for you. Please try again.");
		} finally {
			setLoading(false);
		}
	}

	const forwardButtonName = shouldSkip(formIndex, clientInfo) ? "Skip" : "Next";

	return (
		<main>
			{loading && <Loading />}
			<FormProgress />
			<div className="forms">
				<CareerStage formIndex={1} goToNextSection={goToNextSection} />
				<ReasonForSwitch formIndex={2} />
				<WorkExperience formIndex={3} />
				<EducationalBackground formIndex={4} />
				<Skills formIndex={5} />
				<AreasForImprovement formIndex={6} />
				<Preferences formIndex={7} />
				<Concerns formIndex={8} />
				<Financial formIndex={9} />
				<GeneralInformation formIndex={10} />
				<Email formIndex={11} />
				{errorMessage.length > 0 && <p className="error">{errorMessage}</p>}
			</div>
			{formIndex !== 1 && (
				<div className="flexRow">
					{formIndex !== 1 && (
						<button onClick={goToPreviousSection} className="greyButton">
							Previous
						</button>
					)}

					{formIndex < c.totalFormPages && formIndex !== 1 && (
						<button onClick={goToNextSection}>{forwardButtonName}</button>
					)}
					{formIndex === c.totalFormPages && <button onClick={handleSubmit}>Submit</button>}
				</div>
			)}
		</main>
	);
}

const isEmpty = (property: any): boolean => !property || property.length === 0;

const shouldSkip = (index: number, clientInfo: t.ClientInfo | null): boolean => {
	if (!clientInfo) return false;
	const propertyMap: any = new Map<number, any>([
		[3, clientInfo.workExperience],
		[4, clientInfo.educationalBackground],
		[5, clientInfo.skills],
		[6, clientInfo.areasForImprovement],
		[7, clientInfo.preferences],
		[8, clientInfo.concerns],
		[9, clientInfo.financial],
		[10, clientInfo.location],
		[11, clientInfo.email],
	]);

	console.log("propertyMap.get(index): ", propertyMap.get(index));
	if (propertyMap.get(index) !== undefined && isEmpty(propertyMap.get(index))) return true;

	switch (clientInfo && clientInfo?.careerStage) {
		case e.CareerStages.CAREER_STARTER:
			return isEmpty(clientInfo?.careerInterests);
		case e.CareerStages.CAREER_CHANGER:
			return isEmpty(clientInfo?.desiredCareerPath) && isEmpty(clientInfo.reasonsForCareerChange);
		case e.CareerStages.NEXT_LEVEL_ACHIEVER:
			return isEmpty(clientInfo?.professionalAchievements);
		default:
			return false;
	}
};
