import * as e from "../../data/enums";
import * as image from "../../data/constants/images";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { preventDefaultContext } from "../../utils/utils";
import Loading from "../../components/Loading";
import { useAlert, useUser } from "../../components/UserContext";
import { isSafariBrowser } from "../../utils/deviceCheck";
import SignInWithGoogle, { handleLoginSuccess } from "../../components/SignUpWithGoogle";

export default function Login() {
	const { setUser } = useUser();
	const { triggerAlert } = useAlert();
	const [emailLogin, setEmailLogin] = useState<string>("");
	const [passwordLogin, setPasswordLogin] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [showPassword, setShowPassword] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};
	const navigate = useNavigate();

	async function logInStudent() {
		setLoading(true);
		try {
			const userCredential = await signInWithEmailAndPassword(
				getAuth(),
				emailLogin.toLocaleLowerCase().trim(),
				passwordLogin
			);
			await handleLoginSuccess(userCredential.user, setUser, setLoading, setErrorMessage, triggerAlert, navigate);
		} catch (error) {
			setErrorMessage(
				"Incorrect email and/or password. Did you sign up with Google? Try signing in with Google."
			);
			console.error("SignIn error:", error);
		} finally {
			setLoading(false);
		}
	}

	return (
		<main>
			<div className={`loginSignup ${isSafariBrowser() ? "is-safari" : ""}`}>
				{loading && <Loading />}
				<form
					id="login"
					onSubmit={(e) => {
						e.preventDefault();
						logInStudent();
					}}
				>
					<label>
						Email
						<input
							id="email"
							className="text-area"
							type="text"
							value={emailLogin}
							onChange={(e) => setEmailLogin(e.target.value)}
							required
							autoComplete="email"
						/>
					</label>
					<label className="passwordField">
						Password
						<input
							id="password"
							className="text-area"
							type={showPassword ? "text" : "password"}
							value={passwordLogin}
							onChange={(e) => setPasswordLogin(e.target.value)}
							required
							autoComplete="current-password"
						/>
						<img
							onClick={togglePasswordVisibility}
							className="eyeIcon"
							src={showPassword ? image.ICONS[e.Icons.EYE_OPEN] : image.ICONS[e.Icons.EYE_CLOSED]}
							alt=""
							onContextMenu={preventDefaultContext}
						/>
					</label>
					{errorMessage && <div className="error">{errorMessage}</div>}
					{!loading && <button type="submit">Log In</button>}
					{!loading && <SignInWithGoogle setErrorMessage={setErrorMessage} setLoading={setLoading} />}
				</form>
				<div className="flexRow">
					<Link to="/signup">Or Sign Up</Link>
					<Link to="/forgot_password">I forgot my password</Link>
				</div>
				<Link className="legalDisclaimer" to="https://auriseartraining.com/Legal">
					By logging in, you agree to our latest <u>Terms of Service</u>, <u>Privacy Policy</u>, and{" "}
					<u>Cookie Policy</u>.
				</Link>
			</div>
		</main>
	);
}
