import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../UserContext";

export default function Preferences({ formIndex }: { formIndex: number }): JSX.Element {
	const { formProgressParam } = useParams();
	const { clientInfo, handleChangeClientInfo } = useUser();
	const [charCount, setCharCount] = useState(0);

	if (formIndex !== Number(formProgressParam)) return <></>;
	return (
		<>
			<div className="infoCard">
				<h3>Lifestyle and Work Preferences</h3>
				<p>Preferences that might affect your career change, such as desired work-life balance.</p>
				<textarea
					value={clientInfo?.preferences}
					onChange={(e) => {
						if (e.target.value.length <= 1000) {
							handleChangeClientInfo("preferences")(e);
							setCharCount(e.target.value.length);
						}
					}}
				/>
				<p>{charCount}/1000</p>{" "}
			</div>
		</>
	);
}
