import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../UserContext";

export default function EducationalBackground({ formIndex }: { formIndex: number }): JSX.Element {
	const { formProgressParam } = useParams();
	const { clientInfo, handleChangeClientInfo } = useUser();
	const [charCount, setCharCount] = useState(0);

	if (formIndex !== Number(formProgressParam)) return <></>;
	return (
		<>
			<div className="infoCard">
				<h3>Educational Background</h3>
				<p>
					Your educational history, including degrees, certifications, and any relevant coursework or training
					that could be applicable to your desired career.
				</p>
				<textarea
					value={clientInfo?.educationalBackground}
					onChange={(e) => {
						if (e.target.value.length <= 1000) {
							handleChangeClientInfo("educationalBackground")(e);
							setCharCount(e.target.value.length);
						}
					}}
				/>
				<p>{charCount}/1000</p>
			</div>
		</>
	);
}
