import React, { createContext, useContext, ReactNode, useState, useRef, useEffect } from "react";
import * as t from "../data/types/types";
import { useNavigate } from "react-router-dom";
import * as c from "../data/constants/constants";

interface AlertContextProps {
	showAlert: boolean;
	alertConfig: t.AlertConfig;
	triggerAlert: (message: string, type?: "error" | "success" | "") => void;
	closeAlert: () => void;
}

interface UserContextProps {
	user: t.User | null;
	setUser: React.Dispatch<React.SetStateAction<t.User | null>>;
	clientInfo: t.ClientInfo | null;
	setClientInfo: React.Dispatch<React.SetStateAction<t.ClientInfo | null>>;
	handleChangeClientInfo: (field: keyof t.ClientInfo) => (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
	formIndex: number;
	setFormIndex: React.Dispatch<React.SetStateAction<number>>;
	advice: any;
	setAdvice: React.Dispatch<React.SetStateAction<any>>;
	clickOutside: boolean;
	setClickOutside: React.Dispatch<React.SetStateAction<boolean>>;
	scrollContentRef?: React.RefObject<HTMLDivElement>;
}

const AlertContext = createContext<AlertContextProps | undefined>(undefined);
const UserContext = createContext<UserContextProps | undefined>(undefined);

export const AlertProvider = ({ children }: { children: ReactNode }) => {
	const [showAlert, setShowAlert] = useState(false);
	const [alertConfig, setAlertConfig] = useState({ message: "", type: "" });

	const triggerAlert = (message: string, type?: "error" | "success" | "") => {
		if (type === undefined) type = "";
		setShowAlert(true);
		setAlertConfig({ message: message, type: type });
	};

	const closeAlert = () => {
		setShowAlert(false);
		setAlertConfig({ message: "", type: "" });
	};

	return (
		<AlertContext.Provider value={{ showAlert, alertConfig, triggerAlert, closeAlert }}>
			{children}
		</AlertContext.Provider>
	);
};

export const UserProvider = ({ children }: { children: ReactNode }) => {
	const [user, setUser] = useState<t.User | null>(null);
	// todo: formIndex kan mischien beter helemaal weg en alleen via de url worden bepaald
	const [formIndex, setFormIndex] = useState(1);
	const [clickOutside, setClickOutside] = useState(false);
	const scrollContentRef = useRef<HTMLDivElement>(null);

	const [clientInfo, setClientInfo] = useState<t.ClientInfo | null>(null);
	const localStorageAdvice = localStorage.getItem("advice");
	const [advice, setAdvice] = useState<any>(localStorageAdvice || null);

	// Load clientInfo from sessionStorage when component mounts
	useEffect(() => {
		const storedClientInfo = sessionStorage.getItem("clientInfo");
		if (storedClientInfo) setClientInfo(JSON.parse(storedClientInfo));
	}, []);

	// Update sessionStorage whenever clientInfo changes
	useEffect(() => {
		if (clientInfo) sessionStorage.setItem("clientInfo", JSON.stringify(clientInfo));
	}, [clientInfo]);

	const handleChangeClientInfo =
		(field: keyof t.ClientInfo) =>
		(event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
			const newValue = (event.target as HTMLInputElement | HTMLTextAreaElement).value;
			setClientInfo((prevInfo) => ({
				...(prevInfo as t.ClientInfo),
				[field]: newValue,
			}));
		};

	return (
		<UserContext.Provider
			value={{
				user,
				setUser,
				clientInfo,
				setClientInfo,
				handleChangeClientInfo,
				formIndex,
				setFormIndex,
				advice,
				setAdvice,
				clickOutside,
				setClickOutside,
				scrollContentRef,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

export const useUser = () => {
	const context = useContext(UserContext);
	if (context === undefined) throw new Error("useUser must be used within a UserProvider");
	return context;
};

export const useAlert = () => {
	const context = useContext(AlertContext);
	if (context === undefined) throw new Error("useAlert must be used within a AlertProvider");
	return context;
};
