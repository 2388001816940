import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../UserContext";

export default function Skills({ formIndex }: { formIndex: number }): JSX.Element {
	const { formProgressParam } = useParams();
	const { clientInfo, handleChangeClientInfo } = useUser();
	const [charCount, setCharCount] = useState(0);

	if (formIndex !== Number(formProgressParam)) return <></>;
	return (
		<>
			{/* TODO: add personality: what kind of person are you */}
			<div className="infoCard">
				<h3>Skills and Competencies</h3>
				<p>
					Your assessment of what you consider to be your key strengths. Talk about your technical skills,
					soft skills, and any other competencies you possess that are relevant to the workplace.
				</p>{" "}
				<textarea
					value={clientInfo?.skills}
					onChange={(e) => {
						if (e.target.value.length <= 1000) {
							handleChangeClientInfo("skills")(e);
							setCharCount(e.target.value.length);
						}
					}}
				/>
				<p>{charCount}/1000</p>
			</div>{" "}
		</>
	);
}
