import * as e from "../data/enums";
import * as image from "../data/constants/images";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import { capitalizeFirstLetter, preventDefaultContext, spaceBeforeCapitals } from "../../utils/utils";

export default function Home(): JSX.Element {
	const navigate = useNavigate();

	useEffect(() => {
		navigate("/form/1");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="home">
			<div className="infoSections">
				<div className="infoSection">
					<div className="card cardLeft">
						<img src={image.ICONS[e.Icons.TEXT_BOX]} alt="textBox" />
					</div>
					<div className="card cardRight">
						<p>
							Your <b>personal</b> AI career coach, guiding you towards your <b>dream career</b>
						</p>
					</div>
				</div>

				<div className="infoSection">
					<div className="card cardLeft">
						<p>
							Find your <b>first job</b>, make a <b>career change</b> or aim for the <b>next level</b> in
							your career
						</p>
					</div>
					<div className="card cardRight">
						<img src={image.ICONS[e.Icons.TRACK]} alt="textBox" />
					</div>
				</div>

				<div className="infoSection">
					<div className="card cardLeft">
						<img src={image.ICONS[e.Icons.JOB_SEARCH]} alt="textBox" />
					</div>
					<div className="card cardRight">
						<p>
							Discover which jobs suit you <b>perfectly</b>
						</p>
					</div>
				</div>
			</div>

			<div className="callToAction">
				<p>Ready to embark on your journey?</p>
				<button onClick={() => navigate("/form/1")}>Get my free personal career advise</button>
				{/* <button className="buttonOpen" onClick={() => navigate("/login")}>
					Login
				</button> */}
			</div>
		</div>
	);
}
