import * as e from "../enums";

export const LOGO = "/img/navigation.png";

export const ICONS: Record<any, string> = {
	[e.Icons.GOOGLE]: "/img/google.png",
	[e.Icons.SETTINGS]: "/img/settings.png",
	[e.Icons.SETTINGS_ACTIVE]: "/img/settingsActive.png",
	[e.Icons.TRACK]: "/img/track.png",
	[e.Icons.ADS]: "/img/ads.png",
	[e.Icons.ARROW_LEFT]: "/img/leftArrow.png",
	[e.Icons.ARROW_RIGHT]: "/img/rightArrow.png",
	[e.Icons.EYE_OPEN]: "/img/eyeOpen.png",
	[e.Icons.EYE_CLOSED]: "/img/eyeClosed.png",
	[e.Icons.SAVE]: "/img/save.png",
	[e.Icons.TASK]: "/img/task.png",
	[e.Icons.ERROR_404]: "/img/error404.png",
	[e.Icons.TEXT_BOX]: "/img/textBox.png",
	[e.Icons.JOB_SEARCH]: "/img/jobSearch.png",
	[e.Icons.NEW]: "/img/new.png",
	[e.Icons.CHANGE]: "/img/change.png",
	[e.Icons.LVL_UP]: "/img/lvlUp.png",
};
