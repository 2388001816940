import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../UserContext";

export default function Email({ formIndex }: { formIndex: number }): JSX.Element {
	const { formProgressParam } = useParams();
	const { clientInfo, handleChangeClientInfo } = useUser();

	// const [firstNameCharCount, setFirstNameCharCount] = useState(0);
	// const [lastNameCharCount, setLastNameCharCount] = useState(0);
	// const [emailCharCount, setEmailCharCount] = useState(0);

	if (formIndex !== Number(formProgressParam)) return <></>;
	return (
		<div className="infoCard">
			<h2>That's it!</h2>
			<p>Just leave us your contact information and get your tailor made career advise</p>

			<p>First Name:</p>
			<textarea
				style={{
					height: "3.2em", // Increased height to accommodate padding
					overflow: "hidden",
					padding: "1em", // Add padding as desired
					resize: "none", // Prevents resizing
					display: "flex",
					alignItems: "center", // This will not affect textareas, shown for illustrative purposes
				}}
				rows={1}
				value={clientInfo?.firstName}
				onChange={handleChangeClientInfo("firstName")}
				maxLength={50}
			/>
			{/* <p>{firstNameCharCount} / 50</p> */}

			<p>Last Name:</p>
			<textarea
				style={{
					height: "3.2em", // Increased height to accommodate padding
					overflow: "hidden",
					padding: "1em", // Add padding as desired
					resize: "none", // Prevents resizing
					display: "flex",
					alignItems: "center", // This will not affect textareas, shown for illustrative purposes
				}}
				rows={1}
				value={clientInfo?.lastName}
				onChange={handleChangeClientInfo("lastName")}
				maxLength={50}
			/>
			{/* <p>{lastNameCharCount} / 50</p> */}

			<p>Email:</p>
			<textarea
				style={{
					height: "3.2em", // Increased height to accommodate padding
					overflow: "hidden",
					padding: "1em", // Add padding as desired
					resize: "none", // Prevents resizing
					display: "flex",
					alignItems: "center", // This will not affect textareas, shown for illustrative purposes
				}}
				rows={1}
				value={clientInfo?.email}
				onChange={handleChangeClientInfo("email")}
				maxLength={50}
			/>
			{/* <p>{emailCharCount} / 50</p> */}
		</div>
	);
}
