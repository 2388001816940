import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import React from "react";
import { useAlert, useUser } from "./UserContext";
import * as e from "../data/enums";
import * as image from "../data/constants/images";
import { signUpAndSetUser } from "../pages/login_signup/Signup";
// import fetchAndSetUserAPI from "../services/api/fetchAndSetUserAPI";
import { handleError } from "../utils/utils";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import firebase from "firebase/compat/app";
import { useNavigate } from "react-router-dom";

export default function SignInWithGoogle({
	setLoading,
	setErrorMessage,
}: {
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}) {
	const { user, setUser } = useUser();
	const { triggerAlert } = useAlert();
	const navigate = useNavigate();

	async function signInWithGoogle() {
		const provider = new GoogleAuthProvider();
		setLoading(true);
		try {
			const result = await signInWithPopup(getAuth(), provider);

			const email = result.user.email;
			if (!email) return setErrorMessage("No email found in Google account.");

			const querySnapshot = await firebase.firestore().collection("Students").where("Email", "==", email).get();
			if (querySnapshot.empty) {
				await handleNewUserRegistration(result.user, setUser, triggerAlert, navigate, setErrorMessage);
				return;
			}

			const userDoc = querySnapshot.docs[0];
			if (userDoc?.exists)
				await handleLoginSuccess(result.user, setUser, setLoading, setErrorMessage, triggerAlert, navigate);
		} catch (error: any) {
			console.error(error);
			if (error.code === "auth/popup-closed-by-user") console.log("Popup closed by user.");
			else setErrorMessage(handleError(error).userMessage);
		} finally {
			setLoading(false);
		}
	}

	return (
		<button className="signInWithGoogle" onClick={signInWithGoogle}>
			<img src={image.ICONS[e.Icons.GOOGLE]} alt="Google Logo" />
			Sign in with Google
		</button>
	);
}

async function handleNewUserRegistration(
	user: any,
	setUser: React.Dispatch<React.SetStateAction<any>>,
	triggerAlert: any,
	navigate: any,
	setErrorMessage: any
) {
	const fullName = user.displayName || "Anonymous User";
	const [firstName, lastName] = fullName.split(" ");

	try {
		await signUpAndSetUser(user, user.email, firstName, lastName, setUser, triggerAlert, navigate, setErrorMessage);
	} catch (error) {
		setErrorMessage(handleError(error).userMessage);
	}
}

export async function handleLoginSuccess(
	user: any,
	setUser: React.Dispatch<React.SetStateAction<any>>,
	setLoading: React.Dispatch<React.SetStateAction<boolean>>,
	setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
	triggerAlert: (message: string) => void,
	navigate: any
) {
	setLoading(true);
	try {
		const idTokenResult = await user.getIdTokenResult();
		if (idTokenResult.claims.role === "Student") {
			// TODO: fetch user data from API
			// await fetchAndSetUserAPI(user.email, setUser, triggerAlert);
			navigate("/");
		} else {
			getAuth().signOut();
			setErrorMessage("User is not a student.");
		}
	} catch (error: any) {
		setErrorMessage(handleError(error).userMessage);
	} finally {
		setLoading(false);
	}
}

export async function reauthenticateUserWithGoogle(
	user: any,
	setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
	triggerAlert: (message: string, type?: "error" | "success" | "") => void
) {
	const provider = new GoogleAuthProvider();
	try {
		const result = await signInWithPopup(getAuth(), provider);
		const email = result.user.email;
		if (email === user?.Email) return result;
		setErrorMessage(
			"The Google account that you've tried to sign in with doesn't match your account. Please try again."
		);
	} catch (error: any) {
		triggerAlert("Re-authentication failed. Please try again.", "error");
		return null;
	}
	return null;
}
