import * as e from "../../data/enums";
// import * as t from "../../data/types/types";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../UserContext";

export default function ReasonForSwitch({ formIndex }: { formIndex: number }): JSX.Element {
	const { clientInfo, handleChangeClientInfo } = useUser();
	const stageParam = clientInfo?.careerStage;

	const [starterCharCount, setStarterCharCount] = useState(0);
	const [changerCharCount1, setChangerCharCount1] = useState(0);
	const [changerCharCount2, setChangerCharCount2] = useState(0);
	const [achieverCharCount, setAchieverCharCount] = useState(0);

	const { formProgressParam } = useParams();
	if (formIndex !== Number(formProgressParam)) return <></>;
	return (
		<>
			{stageParam === e.CareerStages.CAREER_STARTER ? (
				<div className="infoCard">
					<h3>Career Interests</h3>
					<p>
						An understanding of the industries or fields you are interested in, including any specific
						companies or roles you aspire to.
					</p>
					<textarea
						value={clientInfo?.careerInterests}
						onChange={(e) => {
							if (e.target.value.length <= 1000) {
								handleChangeClientInfo("careerInterests")(e);
								setStarterCharCount(e.target.value.length);
							}
						}}
					/>
					<p>{starterCharCount}/1000</p>
				</div>
			) : stageParam === e.CareerStages.CAREER_CHANGER ? (
				<>
					<div className="infoCard">
						<h3>Reasons for Career Change</h3>
						<p>
							Your motivations for wanting to change careers, including any factors that are driving your
							dissatisfaction with your current career or attracting you to a new one.
						</p>
						<textarea
							value={clientInfo?.reasonsForCareerChange}
							onChange={(e) => {
								if (e.target.value.length <= 1000) {
									handleChangeClientInfo("reasonsForCareerChange")(e);
									setChangerCharCount1(e.target.value.length);
								}
							}}
						/>
						<p>{changerCharCount1}/1000</p>
					</div>
					<div className="infoCard">
						<h3>Desired Career Path</h3>
						<p>
							Specific details about the industry, role, or type of work you are interested in
							transitioning to, including any particular companies or sectors you are targeting.
						</p>
						<textarea
							value={clientInfo?.desiredCareerPath}
							onChange={(e) => {
								if (e.target.value.length <= 1000) {
									handleChangeClientInfo("desiredCareerPath")(e);
									setChangerCharCount2(e.target.value.length);
								}
							}}
						/>
						<p>{changerCharCount2}/1000</p>
					</div>
				</>
			) : (
				stageParam === e.CareerStages.NEXT_LEVEL_ACHIEVER && (
					<div className="infoCard">
						<h3>Professional Achievements</h3>
						<p>
							Highlights of your key accomplishments in your current and past roles, including any metrics
							or outcomes that demonstrate your impact on the organization.
						</p>
						<textarea
							value={clientInfo?.professionalAchievements}
							onChange={(e) => {
								if (e.target.value.length <= 1000) {
									handleChangeClientInfo("professionalAchievements")(e);
									setAchieverCharCount(e.target.value.length);
								}
							}}
						/>
						<p>{achieverCharCount}/1000</p>
					</div>
				)
			)}
		</>
	);
}
