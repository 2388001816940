import React from "react";
import * as c from "../data/constants/constants";
import { useParams } from "react-router-dom";

export default function FormProgress(): JSX.Element {
	const { formProgressParam } = useParams();
	const formIndex = Number(formProgressParam);

	const progress = (formIndex / c.totalFormPages) * 100;

	return (
		<div className="progressBar">
			<div className="progressWrapper">
				<div className="progressText">
					<div className="explanation">Let's get to know you</div>
					<div className="score">
						{formIndex} / {c.totalFormPages}
					</div>
				</div>

				<div className="progressTotal"></div>
				<div className="progressCurrent" style={{ width: `${progress}%` }}></div>
			</div>
		</div>
	);
}
