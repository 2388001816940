import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/style.css";
import Header from "./components/Header";
import Home from "./pages/Home";
import Login from "./pages/login_signup/Login";
import Signup from "./pages/login_signup/Signup";
import ForgotPassword from "./pages/login_signup/ForgotPassword";
import ErrorPage from "./pages/ErrorPage";
import CustomAlert from "./components/CustomAlert";
import { useAlert, useUser } from "./components/UserContext";
import Form from "./pages/Form";
import Advice from "./pages/Advice";
import Footer from "./components/Footer";
import Legal from "./pages/legal/Legal";
import Contact from "./pages/Contact";

export default function App() {
	const { showAlert, triggerAlert } = useAlert();
	const [adSpaceClassName, setAdSpaceClassName] = useState("centerWithoutAd");
	const { user, setUser, scrollContentRef, clickOutside, setClickOutside } = useUser();

	// useEffect(() => {
	// initializeAuthState(user, setUser, setClassData, triggerAlert, setLoading);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [setUser, setClassData]);

	// to make sure scroll works, also when mouse is on left or right div instead of center div
	const handleScroll = (e: React.WheelEvent) => {
		if (scrollContentRef?.current) {
			const { deltaY } = e;
			scrollContentRef.current.scrollTop += deltaY;
		}
	};

	// to make sure user can click outside of modal to close it
	const handleDivClick = () => {
		setClickOutside(!clickOutside);
	};

	return (
		<Router>
			<div className="App">
				<div className="left" onWheel={handleScroll} onClick={handleDivClick}></div>

				<div className={`center ${adSpaceClassName}`}>
					<Header />
					{showAlert && <CustomAlert />}
					<AllRoutes />
					<Footer />
				</div>
				<div className="right" onWheel={handleScroll} onClick={handleDivClick}></div>
			</div>
		</Router>
	);
}

function AllRoutes() {
	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/form" element={<Form />} />
			<Route path="/form/:formProgressParam" element={<Form />} />
			<Route path="/advice/" element={<Advice />} />

			<Route path="/login" element={<Login />} />
			<Route path="/signup" element={<Signup />} />
			<Route path="/forgot_password" element={<ForgotPassword />} />

			<Route path="/legal" element={<Legal />} />
			<Route path="/contact" element={<Contact />} />

			<Route path="/404" element={<ErrorPage message="Page not found" />} />
			<Route path="/*" element={<Home />} />
		</Routes>
	);
}
