import React, { useState } from "react";
import { getAuth } from "firebase/auth";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { Link, useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { handleError } from "../../utils/utils";
import { useAlert } from "../../components/UserContext";
import { isSafariBrowser } from "../../utils/deviceCheck";

export default function ForgotPassword(): JSX.Element {
	const { triggerAlert } = useAlert();
	const [resetEmail, setResetEmail] = useState("");
	const navigate = useNavigate();

	const handleResetPassword = (email: string) => {
		const auth = getAuth();
		sendPasswordResetEmail(auth, email.toLowerCase().trim())
			.then(() => {
				triggerAlert("Password reset link sent to your email", "success");
				navigate("/login");
			})
			.catch((error) => {
				triggerAlert(handleError(error).userMessage, "error");
			});
	};

	return (
		<main>
			<div className={`loginSignup ${isSafariBrowser() ? "is-safari" : ""}`}>
				<form
					id="ForgotPassword"
					onSubmit={(e) => {
						e.preventDefault();
						handleResetPassword(resetEmail);
					}}
				>
					<label>
						Enter your email address to reset your password
						<input
							id="email"
							className="text-area"
							type="text"
							value={resetEmail}
							onChange={(e) => setResetEmail(e.target.value)}
							required
							autoComplete="email"
						/>
					</label>
					<button type="submit" value="Reset Password">
						Reset Password
					</button>
				</form>
				<Link to="/login">Back to Login</Link>
			</div>
		</main>
	);
}
