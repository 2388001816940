import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function Footer(): JSX.Element {
	const location = useLocation();
	const isLegal = location.pathname === "/Legal";

	const currentYear = new Date().getFullYear();
	return (
		<footer>
			<nav>
				{isLegal ? <Link to="/">Home</Link> : <Link to="/Legal">Legal</Link>}
				<Link to="/Contact">Contact</Link>
			</nav>
			<div className="rights">&copy; {currentYear} Octo Octo. All rights reserved.</div>
		</footer>
	);
}
