import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../UserContext";

export default function Financial({ formIndex }: { formIndex: number }): JSX.Element {
	const { formProgressParam } = useParams();
	const { clientInfo, handleChangeClientInfo } = useUser();
	const [charCount, setCharCount] = useState(0);

	if (formIndex !== Number(formProgressParam)) return <></>;
	return (
		<>
			<div className="infoCard">
				<h3>Financial Considerations</h3>
				<p>What salary range are you looking for?</p>
				<textarea
					value={clientInfo?.financial}
					onChange={(e) => {
						if (e.target.value.length <= 100) {
							handleChangeClientInfo("financial")(e);
							setCharCount(e.target.value.length);
						}
					}}
				/>
				<p>{charCount}/100</p>{" "}
			</div>
		</>
	);
}
