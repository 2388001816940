import * as e from "../../data/enums";
import * as image from "../../data/constants/images";
import * as t from "../../data/types/types";
import React from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../UserContext";

export default function CareerStage({
	formIndex,
	goToNextSection,
}: {
	formIndex: number;
	goToNextSection: () => void;
}): JSX.Element {
	const { formProgressParam } = useParams();
	const { clientInfo, setClientInfo } = useUser();

	function handleOptionClick(option: e.CareerStages) {
		const newClientInfo: t.ClientInfo = clientInfo || {
			email: "",
			firstName: "",
			lastName: "",
			currency: "euros",
			careerStage: option,
		};
		newClientInfo.careerStage = option;
		setClientInfo(newClientInfo);
		goToNextSection();
	}

	if (formIndex !== Number(formProgressParam)) return <></>;
	return (
		<>
			<h3>
				<b>Select what describes you best:</b>
			</h3>
			<div className="option" onClick={() => handleOptionClick(e.CareerStages.CAREER_STARTER)}>
				<img src={image.ICONS[e.Icons.NEW]} alt="careerStarter" />
				<div className="optionText">
					<h2>Career Starter</h2>
					<p>
						You are new to the workforce or a recent graduate looking to navigate your entry into your
						chosen field.
					</p>
				</div>
			</div>
			<div className="option" onClick={() => handleOptionClick(e.CareerStages.CAREER_CHANGER)}>
				<img src={image.ICONS[e.Icons.CHANGE]} alt="careerChanger" />
				<div className="optionText">
					<h2>Career Changer</h2>
					<p>
						You are a professionals who is considering to make a transition to a different industry or
						career path.
					</p>
				</div>
			</div>
			<div className="option" onClick={() => handleOptionClick(e.CareerStages.NEXT_LEVEL_ACHIEVER)}>
				<img src={image.ICONS[e.Icons.LVL_UP]} alt="nextLevelAchiever" />
				<div className="optionText">
					<h2>Next-Level Achiever</h2>
					<p>You are an ambitious professional ready to elevate your career.</p>
				</div>
			</div>
		</>
	);
}
