import React from "react";
import { CookiePolicyContent } from "./CookiePolicy";
import { PrivacyPolicyContent } from "./PrivacyPolicy";
import { TermsOfServiceContent } from "./TermsOfService";

export default function Legal(): JSX.Element {
	return (
		<main className="legal">
			<section className="card">
				<div className="card-text">
					<details>
						<summary>
							<h2>Cookie Policy for Job Advisor</h2>
						</summary>
						<CookiePolicyContent />
					</details>
				</div>
			</section>

			<section className="card">
				<div className="card-text">
					<details>
						<summary>
							<h2>Privacy Policy for Job Advisor</h2>
						</summary>
						<PrivacyPolicyContent />
					</details>
				</div>
			</section>

			<section className="card">
				<div className="card-text">
					<details>
						<summary>
							<h2>Terms of Service for Job Advisor</h2>
						</summary>
						<TermsOfServiceContent />
					</details>
				</div>
			</section>

		</main>
	);
}
