import React from "react";

export default function Contact(): JSX.Element {
	return (
		<main>
			<div className="infoCard">
				<div>
					<h1>Contact</h1>
					<span>info@auriseartraining.com</span>
				</div>
			</div>
		</main>
	);
}
