import React, { useEffect } from "react";
import { LOGO } from "../data/constants/images";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "./UserContext";
import { preventDefaultContext } from "../utils/utils";
// import AdComponent from "../components/AdBanner";
// import useDetermineToShowAd from "../hooks/useDetermineToShowAd";

export default function Header(): JSX.Element {
	const { setFormIndex } = useUser();
	const navigate = useNavigate();

	const handleClick = () => {
		setFormIndex(1);
		navigate("/");
	};

	return (
		<div className="top">
			{/* {showAd && <AdComponent />} */}
			<header onClick={() => handleClick()}>
				<div className="logo">
					<img alt="logo" src={LOGO} onClick={() => navigate("/")} onContextMenu={preventDefaultContext} />
					<h1>Job Advisor</h1>
				</div>
				<h2>Unlock Your Potential. Shape Your Future.</h2>
			</header>
		</div>
	);
}

// function determinePath(pathname: string) {
// 	const paths = pathname.split("/");
// 	if (paths[1] === "shortcut" || paths[1] === "practice") {
// 		if (paths.length > 3) return paths[2] + "/" + paths[3];
// 		return paths[2];
// 	}
// 	if (paths.length > 3) return paths[1] + "/" + paths[2];
// 	return paths.length <= 2 ? "./" : "./" + paths[1];
// }
