import * as e from "../../data/enums";
// import * as t from "../../data/types/types";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../UserContext";

export default function WorkExperience({ formIndex }: { formIndex: number }): JSX.Element {
	const { clientInfo, handleChangeClientInfo } = useUser();
	const [charCount, setCharCount] = useState(0);

	const { formProgressParam } = useParams();
	if (formIndex !== Number(formProgressParam)) return <></>;
	return (
		<div className="infoCard">
			<h3>Work Experience</h3>
			<p>
				Even if limited, details about any internships, part-time jobs, volunteer work, or projects that could
				be relevant to your desired career path.
			</p>
			<textarea
				value={clientInfo?.workExperience}
				onChange={(e) => {
					if (e.target.value.length <= 1000) {
						handleChangeClientInfo("workExperience")(e);
						setCharCount(e.target.value.length);
					}
				}}
			/>
			<p>{charCount}/1000</p>
		</div>
	);
}
