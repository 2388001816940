import * as t from "../../data/types/types";

export default async function chatGPTAPI(ClientInfo: t.ClientInfo) {
	console.log("API CALL: chatGPTAPI");
	try {
		const data = await fetch("https://career-advisor-backend50000.vercel.app/get-long-response-plus-sign-up", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ clientInfo: ClientInfo }),
		}).then((data) => data.json());
		let str = data.response.trim();
		return str;
	} catch (error) {
		console.log(error);
		throw new Error("Sorry, we couldn't generate advice for you. Please try again.");
	}
}

// export default async function chatGPTAPI(prompt: string) {
// 	console.log("API CALL: chatGPTAPI");
// 	try {
// 		const data = await fetch("https://career-advisor-backend50000.vercel.app/get-response", {
// 			method: "POST",
// 			headers: {
// 				"Content-Type": "application/json",
// 			},
// 			body: JSON.stringify({ text: prompt }),
// 		}).then((data) => data.json());
// 		let str = data.response.trim();
// 		return str;
// 	} catch (error) {
// 		console.log(error);
// 		throw new Error("Sorry, we couldn't generate advice for you. Please try again.");
// 	}
// }
