// TODO: replace with actual IDs
export enum StripePriceID {
	DAILY_TEST = "price_1OFCgRKN1cjGH30ARB3znauD",
	MONTHLY = "price_1OiN8KKN1cjGH30Ay8OQkbZN", // Real
	YEARLY = "price_1OiMxeKN1cjGH30AJwqePsX0", // Real
}

// export enum Subscription {
// 	MONTHLY = "monthly",
// 	YEARLY = "yearly",
// 	LIFETIME = "lifetime",
// 	FREE = "free",
// }

export const enum LOGIN_METHODS {
	PASSWORD = "password",
	GOOGLE = "google.com",
	GOOGLE_AND_PASSWORD = "google.com and password",
	NONE_EXISTENT = "non-existent",
}

export const enum CareerStages {
	CAREER_STARTER = "careerStarter",
	CAREER_CHANGER = "careerChanger",
	NEXT_LEVEL_ACHIEVER = "nextLevelAchiever",
}

export enum Icons {
	SETTINGS = "settings",
	SETTINGS_ACTIVE = "settingsActive",
	TRACK = "track",
	ADS = "ads",
	ARROW_LEFT = "leftArrow",
	ARROW_RIGHT = "rightArrow",
	EYE_OPEN = "eyeOpen",
	EYE_CLOSED = "eyeClosed",
	SAVE = "save",
	TASK = "task",
	GOOGLE = "google",
	ERROR_404 = "error404",
	TEXT_BOX = "textBox",
	JOB_SEARCH = "jobSearch",
	NEW = "new",
	CHANGE = "change",
	LVL_UP = "lvlUp",
}
