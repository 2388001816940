import * as t from "../../data/types/types";
import * as e from "../../data/enums";
import * as image from "../../data/constants/images";
import React, { useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
// import signUpWithEmail from "../../services/auth/signUpWithEmail";
// import createUser from "../../services/api/createUserAPI";
import Loading from "../../components/Loading";
import { capitalizeFirstLetter, handleError, preventDefaultContext } from "../../utils/utils";
import { isSafariBrowser } from "../../utils/deviceCheck";
import { useAlert, useUser } from "../../components/UserContext";
// import fetchAndSetUserAPI from "../../services/api/fetchAndSetUserAPI";
import SignInWithGoogle from "../../components/SignUpWithGoogle";

export default function Signup() {
	const { setUser } = useUser();
	const { triggerAlert } = useAlert();
	const [studentEmail, setStudentEmail] = useState("");
	const [studentPassword, setStudentPassword] = useState("");
	const [studentPasswordCheck, setStudentPasswordCheck] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [loading, setLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordCheck, setShowPasswordCheck] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const togglePasswordCheckVisibility = () => {
		setShowPasswordCheck(!showPasswordCheck);
	};

	const navigate = useNavigate();
	const handleSignUp = useCallback(async () => {
		if (studentPassword !== studentPasswordCheck) return setErrorMessage("Passwords do not match");

		setLoading(true);
		try {
			// let userCredential = await signUpWithEmail(studentEmail, studentPassword);
			// signUpAndSetUser(
			// 	userCredential,
			// 	studentEmail,
			// 	firstName,
			// 	lastName,
			// 	setUser,
			// 	triggerAlert,
			// 	navigate,
			// 	setErrorMessage
			// );
		} catch (firebaseError) {
			setErrorMessage(handleError(firebaseError).userMessage);
		} finally {
			setLoading(false);
		}
	}, [studentEmail, studentPassword, studentPasswordCheck, firstName, lastName, navigate, setUser, triggerAlert]);

	return (
		<main>
			<div className={`loginSignup ${isSafariBrowser() ? "is-safari" : ""}`}>
				{loading && <Loading />}
				<form
					id="signup"
					onSubmit={(e) => {
						e.preventDefault();
						handleSignUp();
					}}
				>
					<label>
						First Name
						<input
							id="firstName"
							type="text"
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
							required
							autoComplete="given-name"
						/>
					</label>
					<label>
						Last Name
						<input
							id="lastName"
							type="text"
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
							required
							autoComplete="family-name"
						/>
					</label>
					<label>
						Email
						<input
							id="email"
							type="email"
							value={studentEmail}
							onChange={(e) => setStudentEmail(e.target.value)}
							required
							autoComplete="email"
						/>
					</label>
					<label className="passwordField">
						Password
						<input
							id="password"
							type={showPassword ? "text" : "password"}
							value={studentPassword}
							onChange={(e) => setStudentPassword(e.target.value)}
							required
							autoComplete="new-password"
						/>
						<img
							onClick={togglePasswordVisibility}
							className="eyeIcon"
							src={showPassword ? image.ICONS[e.Icons.EYE_OPEN] : image.ICONS[e.Icons.EYE_CLOSED]}
							alt="toggle password visibility"
							onContextMenu={preventDefaultContext}
						/>
					</label>
					<label className="passwordField">
						Repeat password
						<input
							id="passwordCheck"
							type={showPasswordCheck ? "text" : "password"}
							value={studentPasswordCheck}
							onChange={(e) => setStudentPasswordCheck(e.target.value)}
							required
							autoComplete="new-password"
						/>
						<img
							onClick={togglePasswordCheckVisibility}
							className="eyeIcon"
							src={showPasswordCheck ? image.ICONS[e.Icons.EYE_OPEN] : image.ICONS[e.Icons.EYE_CLOSED]}
							alt="toggle password visibility"
							onContextMenu={preventDefaultContext}
						/>
					</label>
					{errorMessage && <div className="error">{errorMessage}</div>}
					<button type="submit" value="Sign Up">
						Sign Up
					</button>
				</form>
				<SignInWithGoogle setErrorMessage={setErrorMessage} setLoading={setLoading} />
				<Link to="/login">Already have an account? Login</Link>
				<Link className="legalDisclaimer" to="https://auriseartraining.com/Legal">
					By signing up, you agree to our latest <u>Terms of Service</u>, <u>Privacy Policy</u>, and{" "}
					<u>Cookie Policy</u>.
				</Link>
			</div>
		</main>
	);
}

export async function signUpAndSetUser(
	userCredential: any, //TODO: type this
	studentEmail: string,
	firstName: string,
	lastName: string,
	setUser: React.Dispatch<React.SetStateAction<t.User | null>>,
	triggerAlert: (message: string, type?: "error" | "success" | "") => void,
	navigate: (to: string) => void,
	setErrorMessage: React.Dispatch<React.SetStateAction<string>>
) {
	try {
		const studentData = createStudentData(studentEmail, firstName, lastName);
		// await createUser(studentData, triggerAlert);
		// await fetchAndSetUserAPI(studentEmail, setUser, triggerAlert);
		navigate("/");
	} catch (axiosError) {
		await userCredential.user?.delete();
		setErrorMessage(handleError(axiosError).userMessage);
	}
}

const createStudentData = (email: string, firstName: string, lastName: string) => {
	return {
		// PERSONAL INFO
		FirstName: capitalizeFirstLetter(firstName),
		LastName: capitalizeFirstLetter(lastName),
		Email: email.toLocaleLowerCase().trim(),
		// SubscriptionPlan: e.Subscription.FREE,
	};
};
