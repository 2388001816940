import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../UserContext";

export default function Concerns({ formIndex }: { formIndex: number }): JSX.Element {
	const { formProgressParam } = useParams();
	const { clientInfo, handleChangeClientInfo } = useUser();
	const [charCount, setCharCount] = useState(0);

	if (formIndex !== Number(formProgressParam)) return <></>;
	return (
		<div className="infoCard">
			<h3>Challenges and Concerns</h3>
			<p>
				Any specific challenges or concerns you have concerning your new career such as gaps in knowledge,
				skills, experience or personal obstacles.
			</p>
			<textarea
				value={clientInfo?.concerns}
				onChange={(e) => {
					if (e.target.value.length <= 1000) {
						handleChangeClientInfo("concerns")(e);
						setCharCount(e.target.value.length);
					}
				}}
			/>
			<p>{charCount}/1000</p>{" "}
		</div>
	);
}
