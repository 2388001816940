import * as e from "../data/enums";
import React from "react";
import { UserFriendlyError, IAuthError, ERROR_MESSAGES } from "../data/constants/errorMessages";
import { getAuth } from "firebase/auth";

// TODO: check if the error is related to not having an internetconnection
export function handleError(error: any): UserFriendlyError {
	// Log technical message for developers
	console.log("Error log:", error);

	if (typeof error.code === "string" && typeof error.message === "string") {
		// Handle known error codes
		if (error.code in ERROR_MESSAGES) return ERROR_MESSAGES[error.code as IAuthError["code"]];

		// Handle unknown error codes
		return {
			userMessage:
				"An unexpected error occurred. You might not be connected to the internet. Please try again later.",
			logMessage: `Unknown error code: ${error.code}. Message: ${error.message}`,
		};
	}

	// Handle completely unexpected errors
	return {
		userMessage:
			"An unexpected error occurred. You might not be connected to the internet. Please try again later.",
		logMessage: `Unexpected error structure: ${JSON.stringify(error, null, 2)}`,
	};
}

export function capitalizeFirstLetter(str: string): string {
	// split by space or _
	return str
		.replace(/_/g, " ")
		.split(" ")
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(" ");
}

export function spaceBeforeCapitals(input: string): string {
	return input.replace(/(?=[A-Z,&,0-9])/g, " ").trim();
}

export function superscriptText(input: string): JSX.Element {
	// This regular expression matches the number followed by an ordinal indicator
	const regex = /(\d+)(st|nd|rd|th)/g;

	// Split the input string by the regular expression to get an array of strings and ordinal numbers
	const parts = input.split(regex);

	// Map over the parts and construct JSX for each part
	const output = parts.map((part, index) => {
		// Check if the part is an ordinal indicator, if so, wrap it with <sup>
		if (part === "st" || part === "nd" || part === "rd" || part === "th") return <sup key={index}>{part}</sup>;
		return part;
	});

	return <>{output}</>;
}

export function determineSuperscriptText(input: number): string {
	const lastTwoDigits = input % 100;
	if (lastTwoDigits > 3 && lastTwoDigits < 21) return "th";
	const lastDigit = input % 10;
	if (lastDigit === 1) return "st";
	if (lastDigit === 2) return "nd";
	if (lastDigit === 3) return "rd";
	return "th";
}

export function preventDefaultContext(event: React.MouseEvent) {
	event.preventDefault();
}

export function preloadImage(url: string) {
	const img = new Image();
	img.src = url;
}

// export function isPayingUser(user: t.User | null): boolean {
// 	if (!user) return false;
// 	return user.SubscriptionPlan === e.Subscription.MONTHLY || user.SubscriptionPlan === e.Subscription.YEARLY;
// }

// export function formatScore(score: number | null) {
// 	if (score === null) return "-";
// 	const formattedScore = score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
// 	return formattedScore + "\u00A0pts";
// }

export function getLoginMethod() {
	const auth = getAuth();
	const authUuser = auth.currentUser;
	if (!authUuser) return e.LOGIN_METHODS.NONE_EXISTENT;

	if (authUuser.providerData.length === 1) {
		if (authUuser.providerData[0].providerId === "google.com") return e.LOGIN_METHODS.GOOGLE;
		if (authUuser.providerData[0].providerId === "password") return e.LOGIN_METHODS.PASSWORD;
	}
	if (authUuser.providerData.length === 2) return e.LOGIN_METHODS.GOOGLE_AND_PASSWORD;
	return e.LOGIN_METHODS.NONE_EXISTENT;
}
