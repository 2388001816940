import React from "react";

export default function TermsOfService(): JSX.Element {
	return (
		<main className="legal">
			<section className="card">
				<div className="card-text">
					<h1>Terms of Service for Job Advisor</h1>
					<TermsOfServiceContent />
				</div>
			</section>
		</main>
	);
}

export function TermsOfServiceContent(): JSX.Element {
	return (
		<div className="legal">
			<div className="legal-header">
				<p>
					<strong>Last Revised:</strong> 24 March 2024
				</p>
			</div>

			<article>
				<h2>1. Introduction</h2>
				<p>
					Welcome to Job Advisor. Our web tool, accessible through our website, provides personalized job
					advice after you fill in a series of questions. This service includes both free and premium options.
					By accessing and using Job Advisor, you agree to be bound by these Terms of Service. Please read
					them carefully.
				</p>
			</article>

			<article>
				<h2>2. Privacy Policy</h2>
				<p>
					Your use of Job Advisor is also governed by our Privacy Policy, which can be found{" "}
					<a href="https://jobadvisor.com/PrivacyPolicy">here</a>.
				</p>
			</article>

			<article>
				<h2>3. Payments, Cancellations, and Refunds</h2>
				<p>
					a. Job Advisor offers a free option and a premium option that requires a one-time payment. Detailed
					pricing information is available on our website.
				</p>
				<p>
					b. Payments for the premium option are processed through Stripe. By making a purchase, you agree to
					Stripe's terms and conditions.
				</p>
				<p>
					c. Due to the nature of our service, we do not offer refunds once access to the premium content has
					been granted. However, exceptions may be considered on a case-by-case basis at our discretion.
				</p>
				<p>
					d. We strive to provide uninterrupted service but acknowledge that occasional downtime may occur. We
					are not liable for losses caused by service interruptions.
				</p>
			</article>

			<article>
				<h2>4. Intellectual Property Rights and License Grant</h2>
				<p>
					Job Advisor grants you a limited, non-exclusive, non-transferable, and revocable license to use our
					services for your personal, non-commercial use, subject to these Terms of Service.
				</p>
			</article>

			<article>
				<h2>5. Third-Party Services</h2>
				<p>
					Our service utilizes third-party services such as Google Analytics for traffic analysis, the OpenAI
					API for processing your queries to provide job advice, and Brevo for email communications and
					mailing list management. Please be aware that these third-party services operate under their own
					Terms of Service and privacy policies. We encourage you to review these documents to understand how
					they collect, use, and share your information. By using Job Advisor, you agree to be bound by the
					Terms of Service of these third-party services as applicable. Specifically, when you sign up for our
					service, your email address and name are processed and stored by Brevo for the purpose of managing
					our mailing list and sending you updates and information about our services.
				</p>
			</article>

			<article>
				<h2>6. Changes to Terms</h2>
				<p>
					We may modify these Terms of Service from time to time. The "Last Revised" date at the top will
					indicate when the latest changes were made. By continuing to use Job Advisor after changes are made,
					you agree to be bound by the revised terms.
				</p>
			</article>

			<article>
				<h2>7. Contact Us</h2>
				<p>
					If you have any concerns or questions about our Terms of Service, please contact us at:{" "}
					<a href="mailto:info@auriseartraining.com">info@auriseartraining.com</a>.
				</p>
			</article>
		</div>
	);
}
