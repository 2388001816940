import React from "react";

export default function PrivacyPolicy(): JSX.Element {
	return (
		<main className="legal">
			<section className="card">
				<div className="card-text">
					<h1>Privacy Policy for Job Advisor</h1>
					<PrivacyPolicyContent />
				</div>
			</section>
		</main>
	);
}

export function PrivacyPolicyContent(): JSX.Element {
	return (
		<div className="legal">
			<div className="legal-header">
				<p>
					<strong>Last Revised:</strong> 24 March 2024
				</p>
			</div>
			<article>
				<h2>Our Privacy Policy</h2>
				<p>
					This privacy policy explains how we handle information through our Job Advisor tool. We update this
					policy to reflect changes in our services and encourage you to review it regularly.
				</p>
			</article>
			<article>
				<h2>Information Collection and Use</h2>
				<p>
					While we do not store personal information from the questionnaire on our servers, we collect your
					first name, last name, and email address for communication purposes, stored in Brevo. When
					processing payments, we use Stripe, a third-party payment processor. Through Stripe, we have access
					to limited transactional information for the purposes of processing payments and managing
					transactions. This may include the payer’s name, email address, and payment method details. However,
					full credit card numbers are not disclosed to us by Stripe.
				</p>
			</article>
			<article>
				<h2>Google Analytics and OpenAI ChatGPT API</h2>
				<p>
					We use Google Analytics to analyze how our service is used and to improve your experience. The
					OpenAI ChatGPT API is utilized to generate personalized job advice based on your inputs. Both
					services are governed by their respective privacy policies, and we do not control their data
					collection methods.
				</p>
			</article>
			<article>
				<h2>Automatic Mailing List Subscription</h2>
				<p>
					By engaging with our service, you are automatically added to our mailing list through Brevo, from
					which you can opt out at any time using the unsubscribe link provided in the communication.
				</p>
			</article>
			<article>
				<h2>Children's Privacy</h2>
				<p>
					We do not knowingly collect personal information from children under 13. If we learn that we have
					collected such information, we will take steps to delete it promptly.
				</p>
			</article>
			<article>
				<h2>Data Retention and Security</h2>
				<p>
					The personal information collected through Brevo for mailing list purposes and transactional data
					handled by Stripe is governed by their respective privacy and data retention policies. Since we do
					not store personal information on our servers, the aspect of data retention and security for such
					information is managed by Brevo and Stripe. We rely on these third-party services to implement
					industry-standard security measures for the data they control. For any information directly
					processed by us, we ensure it is handled securely in transit to these services but do not retain it
					thereafter.
				</p>
			</article>
			<article>
				<h2>Updates to This Privacy Policy</h2>
				<p>
					We may update this policy at our discretion. Changes become effective upon posting. We will notify
					users of significant changes.
				</p>
			</article>
			<article>
				<h2>Contact Us</h2>
				<p>
					If you have any concerns or questions about our privacy practices, please contact us at:{" "}
					<a href="mailto:info@auriseartraining.com">info@auriseartraining.com</a>
				</p>
			</article>
		</div>
	);
}
